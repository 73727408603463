.App
{
    text-align: center;
}

.App-logo
{
    height: 40vmin;

    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference)
{
    .App-logo
    {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header
{
    font-size: calc(10px + 2vmin);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    min-height: 100vh;

    color: white;
    background-color: #282c34;
}

.App-link
{
    color: #61dafb;
}

@keyframes App-logo-spin
{
    from
    {
        transform: rotate(0deg);
    }
    to
    {
        transform: rotate(360deg);
    }
}
