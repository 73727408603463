.form
{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin-bottom: 50px;
}

.input
{
    width: 120px;
    height: 30px;
    margin: 0 10px;
    padding: 0 10px;

    border: 2px solid green;
    border-radius: 10px;
}
